import Glide from '@glidejs/glide'
const SmoothScroll = require('smooth-scroll')
import GLightbox from 'glightbox'

// INITIALIZE SMOOTHSCROLL
new SmoothScroll('a[href*="#"]', {
	speed: 1000
})

// LIGHTBOX INITIALIZE
const lightbox = GLightbox({
	selector: '.lightbox',
	touchNavigation: true,
	type: 'image',
	loop: true,
});

var lightboxtrigger = document.getElementById('lightbox-trigger')
if(lightboxtrigger){
	lightboxtrigger.addEventListener('click', function(e){
		lightbox.open()
	})
}

var videos = document.getElementsByClassName('video-lightbox')

for(let i = 0; i < videos.length; i++){
	videos[i].addEventListener('click', function(e){
		e.preventDefault();
		var url = this.href;
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    var match = url.match(regExp);
		var videoId = (match && match[2].length === 11) ? match[2] : null;
		var iframeMarkup = '<iframe class="position-cover width-1-1 height-1-1" src="https://www.youtube.com/embed/' + videoId + '?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=1&autoplay=1"  width="560" height="315"  frameborder="0" allowfullscreen></iframe>';
		this.innerHTML += iframeMarkup;
	})
}

// const videolightbox = GLightbox({
// 	plyr: {
// 		js: 'https://cdn.plyr.io/3.6.2/plyr.polyfilled.js',
// 	},
// 	selector: '.video-lightbox',
// 	autoplayVideos: true
// });

// ACCORDIONS
var acc = document.getElementsByClassName("accordion__button");

for (let i = 0; i < acc.length; i++) {
	acc[i].addEventListener("click", function() {
		this.classList.toggle("active");
		var panel = this.nextElementSibling;
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + "px";
		}
	});
}

// SLIDERS
var glidefancyslider = document.getElementsByClassName('glide-fancy');

if(glidefancyslider.length > 0){
	var glideitems = document.getElementsByClassName('glide__slide')
	var glidelength = glideitems.length;
	var glide = new Glide('.glide', {
		type: 'carousel',
		autoplay: 10000,
		hoverpause: false,
		animationDuration: 1000,
		animationTimingFunc: 'ease-in-out',
	}).mount()
	var glidestatus = document.getElementById('slider-status')
	glidestatus.innerHTML = (glide.index + 1) + '/' + glidelength; 

	glide.on('run', function(e){
		glidestatus.innerHTML = (glide.index + 1) + '/' + glidelength; 
	})
}

var glidepictureslider = document.getElementsByClassName('glide-picture');

if(glidepictureslider.length > 0){
	var glide = new Glide('.glide', {
		type: 'carousel',
		autoplay: 10000,
		hoverpause: false,
		animationDuration: 1000,
		animationTimingFunc: 'ease-in-out',
		perView: 3,
		breakpoints: {
	    479: {
	      perView: 2
	    },
	  }
	}).mount()
}

// HAMBURGER MENU
var menutrigger = document.getElementById('menu-trigger')
var menutriggertext = document.getElementById('menu-trigger-text')
var menutriggerclose = document.getElementById('menu-trigger-close')
var mobilemenu = document.getElementById('mobile-menu')
var header = document.getElementsByClassName('header')[0]
var body = document.getElementsByTagName('body')[0]

menutrigger.addEventListener('click', function(){
	if(header.classList.contains('header--active')){
		header.classList.remove('header--active')
		body.style.overflow = 'auto'
		body.style.maxHeight = 'auto'
		menutriggertext.classList.remove('d-none')
		menutriggerclose.classList.add('d-none')
	}else{
		header.classList.add('header--active')
		body.style.overflow = 'hidden'
		body.style.maxHeight = '100vh'
		menutriggertext.classList.add('d-none')
		menutriggerclose.classList.remove('d-none')
	}
})

// STICKY NAV
function processOnScroll(){
	if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
		document.getElementsByClassName('header')[0].classList.add('header--sticky');
	} else {
		document.getElementsByClassName('header')[0].classList.remove('header--sticky');
	}
}

setTimeout(processOnScroll, 1000);
document.addEventListener('scroll', function(e) {
	processOnScroll()
})

// READ MORE TEKST
var toolong = document.getElementsByClassName('too-long')

if(toolong.length > 0){
	var readmore = document.getElementById('readmore');
	readmore.addEventListener('click', function(){
		var panel = toolong[0];
		panel.classList.add('too-long--expanded')
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + "px";
		}
	})
}